<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          Users
          <v-spacer />
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                fab
                small
                :to="{ path: 'addUsers' }"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </template>
            <span>Add</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Photo</th>
                  <th class="text-left">Email</th>
                  <th class="text-left">Group Name</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listUsers" :key="item.ID">
                  <td>{{ item.NAME }}</td>
                  <td><img :src="item.PHOTO" width="50px" /></td>
                  <td>{{ item.EMAIL }}</td>
                  <td>{{ item.GROUP_NAME }}</td>
                  <td>{{ item.STATUS }}</td>
                  <td>
                    <div class="flex">
                      <!-- <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click.prevent="resetPassModal()"
                          >
                            <v-icon>mdi-key</v-icon>
                          </v-btn>
                        </template>
                        <span>Reset Password</span>
                      </v-tooltip> -->
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="danger"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click.prevent="openMyDialog(item.ID)"
                          >
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-col cols="12">
            <div id="pagination">
              <v-pagination
                v-model="current"
                :length="paginate_total"
                :total-visible="7"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
                circle
                @input="updateCurrent"
              ></v-pagination>
            </div>
          </v-col>
        </v-card-text>
      </base-card>
    </v-col>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="red">
          <span class="text-h5 white--text">Delete Users</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <p class="mb-0 mt-5">Are you sure delete this user?</p>
                <v-text-field v-model="idDel" v-show="false"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn class="white--text" color="red darken-2" @click="deleteUSer">
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="grey darken-2"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Users",
  },
  data() {
    return {
      listUsers: [],
      current: 1,
      paginate_total: 0,
      dialog: false,
      idDel: "",
    };
  },
  created: function () {
    this.getUsers();
    const gemas_token = this.$cookies.get("gemas_token");
  },
  computed: {
    ...mapGetters(["GEMAS_BACKEND"]),
    axiosParams() {
      const params = new URLSearchParams();
      params.append("pagin", this.current);
      return params;
    },
  },
  methods: {
    // Get Product
    getUsers: function () {
      axios
        .get(this.GEMAS_BACKEND + "account/getListUsers", {
          params: this.axiosParams,
        })
        .then((res) => {
          this.listUsers = res.data.account;
          this.paginate_total = Math.ceil(
            res.data.total / res.data.limit_per_page
          );
          console.log(this.listUsers);
        })
        .catch((err) => {
          // handle error
          console.log(err);
        });
    },
    deleteUSer: function () {
      let postData = new FormData();
      postData.append("ID", this.idDel);
      axios
        .post(this.GEMAS_BACKEND + "account/deleteUser", postData)
        .then((res) => {
          this.dialog = false;
          this.getUsers();
          console.log(this.listUsers);
        })
        .catch((err) => {
          // handle error
          console.log(err);
        });
    },
    updateCurrent(i) {
      this.current = i;
      this.getUsers();
    },
    formatCompat(date) {
      var d = new Date(date);
      var ms = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return d.getDate() + " " + ms[d.getMonth()] + " " + d.getFullYear();
    },
    openMyDialog(id) {
      this.dialog = true;
      this.idDel = id;
    },
  },
};
</script>
